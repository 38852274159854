.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  border: 4px solid #cfcbb4;
  margin: 5%;
  margin-top: 2%;
}

.card-text {
  color: #333;
  margin: 0;
}

.delete-button {
  color: firebrick;
}

.todo-input {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.todo-input input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  background-color: transparent;
}

.todo-input button {
  background-color: #4CAF50;
  color: white;
  border: none;
  outline: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.todo-input button:hover {
  background-color: #45a049;
}

.todo-input button:active {
  background-color: #3e8940;
}

.submit-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button button {
  background-color: #ff9f00;
  color: white;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button button:hover {
  background-color: #ff8c00;
}

.submit-button button:active {
  background-color: #ff751a;
}

.suggestion-area {
  margin: 5%;
  margin-top: 2%;
}